
import template from './custom-tables.html';
import FieldsTableModel from '../../../lib/models/FieldsTableModel.js';

/**
 *  CustomTables view model
 */
class CustomTablesViewModel
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.schemas = ko.observableArray([]);
		this.selected_schema = ko.observable();
		this.filtered_tables = ko.observableArray();

		this.selected_schema.subscribe((val) => {
			this.page.load_schema_table_data();
		});
	}

	/* add new table */
	async btn_upsert_field_table()
	{
		let last_table = this.filtered_tables().length > 0 
			? this.filtered_tables()[this.filtered_tables().length - 1] 
			: null;

		let last_table_idx = last_table ? last_table.idx() : -1;

		let res = await Grape.dialog.open('AddEditFieldsTable', {
			fieldsTable: {},
			last_table_idx: last_table_idx
		});

		if (res?.status === 'OK')
			this.page.updateData();
	}

	/* create new field */
	async btn_upsert_field(data)
	{
		let schema = ko.toJS(data).schema || '';
		let table = ko.toJS(data).table || '';
		let fields_table_uuid = ko.toJS(data).fields_table_uuid || '';

		if (schema && table)
		{
			let selected_table = this.filtered_tables().find(t => ko.unwrap(t.fields_table_uuid) === fields_table_uuid);
			let last_field = selected_table?.fields()?.length > 0 
				? selected_table.fields()[selected_table.fields().length - 1] 
				: null;

			let last_field_idx = last_field ? last_field.idx() : -1;

			let res = await Grape.dialog.open('AddEditTableField', {
				tableField: {
					schema,
					table,
					fields_table_uuid
				},
				last_field_idx: last_field_idx
			});

			console.log('res', res);

			if (res?.status === 'OK')
				this.page.load_schema_table_data();
		}
	}

	/* edit field */
	async btn_edit_field(field)
	{
		let result = await Grape.dialog.open('AddEditTableField', {
			edit: true,
			tableField: ko.toJS(field)
		});

		if (result.status === 'OK')
			this.page.load_schema_table_data();
		else
			Grape.alerts.alert({ type: 'error', message: 'Error occured while saving the field' });
	}

	/* delete field */
	async btn_delete_field(data)
	{
		let field = ko.toJS(data);
		let confirm = await Grape.alerts.confirm({type:'warning', message:`Delete Custom Field <b>${field.display_name}( ${field.name} )</b>? This may result in loss of data.`, cancel_type:'default', accept_type:'warning'});

		if (confirm)
		{
			let res = await Grape.fetches.deleteJSON('/api/custom-fields/field/delete', {field_uuid: field.field_uuid});
			if (res?.status === 'OK')
			{
				this.page.load_schema_table_data();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}

	/* edit table */
	async btn_edit_table(data, event)
	{
		let res = await Grape.dialog.open('AddEditFieldsTable', {fieldsTable: data, edit: true});

		if (res?.status === 'OK')
			this.page.load_schema_table_data();
	}

	/* delete table */
	async btn_delete_field_table(data)
	{
		let table = ko.toJS(data);
		let confirm = await Grape.alerts.confirm({type:'warning', title:'Delete', message:`Delete Custom Fields Table <b>${table.display_name}( ${table.schema}.${table.table} )</b>? This may result in loss of data.`, cancel_type:'default', accept_type:'warning'});
		if (confirm)
		{
			let res = await Grape.fetches.deleteJSON('/api/custom-fields/table/delete', {fields_table_uuid: table.fields_table_uuid});
			if (res?.status === 'OK')
			{
				this.page.updateData();
				return res;
			}

			Grape.alerts.apiError(res);
		}
	}

	async btn_move_table_up(index, idx)
	{
		if (index == 0)
			return;

		let table_one = this.filtered_tables()[index];
		let table_two = this.filtered_tables()[index-1];
		table_one.idx(table_two.idx());
		table_two.idx(idx);

		await table_one.save();
		await table_two.save();
		this.page.updateData();
	}

	async btn_move_table_down(index, idx)
	{
		if (index == this.filtered_tables().length -1)
			return;

		let table_one = this.filtered_tables()[index];
		let table_two = this.filtered_tables()[index+1];
		table_one.idx(table_two.idx());
		table_two.idx(idx);

		await table_one.save();
		await table_two.save();
		this.page.updateData();
	}

	async btn_move_fields_up(t_index, f_index, idx)
	{
		if (f_index == 0)
			return;

		let field_one = this.filtered_tables()[t_index].fields()[f_index];
		let field_two = this.filtered_tables()[t_index].fields()[f_index - 1];
		field_one.idx(field_two.idx());
		field_two.idx(idx);

		await field_one.save();
		await field_two.save();
		this.page.load_schema_table_data();
	}

	async btn_move_fields_down(t_index, f_index, idx)
	{
		if (f_index == this.filtered_tables()[t_index].fields.length -1)
			return;

		let field_one = this.filtered_tables()[t_index].fields()[f_index];
		let field_two = this.filtered_tables()[t_index].fields()[f_index + 1];

		if (!field_two)
			return;

		field_one.idx(field_two.idx());
		field_two.idx(idx);

		await field_one.save();
		await field_two.save();
		this.page.load_schema_table_data();
	}
}

/**
 * CustomTables class
 */
class CustomTablesClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new CustomTablesViewModel(this);
		this.name = 'CustomTablesClass';
	}

	async init()
	{}

	async updateData()
	{
		let result = await Grape.fetches.getJSON('/api/record', {
			schema: 'fields',
			table: 'v_fields_schema',
			sortfield: 'schema'
		});

		if (result.status == 'OK')
			this.viewModel.schemas(result.records);
	}

	async load_schema_table_data()
	{
		let schema = this.viewModel.selected_schema().tables.sort((a, b) => a.idx - b.idx);
		if (!schema) return [];

		let promises = (schema ?? []).map(async t => {
			let tbl = new FieldsTableModel({uuid: t.uuid});
			await tbl.fetch();
			return tbl;
		});

		let tables = await Promise.all(promises);
		this.viewModel.filtered_tables(tables);
	}
}

export default {
	route: '[/]ui/setup/custom-tables',
	page_id: '[/]ui/setup/custom-tables',
	page_class: CustomTablesClass,
	template: template,
	title: 'Custom Tables',
	icon: 'fa-regular fa-list',
	idx: 10
};
