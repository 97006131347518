
import template from './setting_edit.html';

class DialogViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.name = ko.observable();
		this.description = ko.observable();
		this.value = ko.observable();
		this.checked = ko.observable();
		this.editing = ko.observable(false);

		this.data_types = ko.observableArray([
			{name: 'Boolean', value: 'bool'},
			{name: 'Integer', value: 'integer'},
			{name: 'Text', value: 'text'},
			{name: 'Interval', value: 'interval'}
		]);
		this.data_type = ko.observable('');
		this.available_tags = ko.observableArray([{name: 'Hidden', value: 'hidden'}]);
		this.selected_tags = ko.observableArray([]);

		this.data_type.subscribe((val) => {

			if (val == 'bool' && typeof this.checked() == 'undefined')
			{
				this.checked(false);
			}

			if (val == 'integer')
			{
				if (typeof this.value() != 'number')
					this.value(1);
			}

			if (val == 'interval')
			{
				try {
					if (typeof this.value() == 'number')
						this.value(this.dialog.format_time(this.value()));
					else
						this.value('00:00:01');
				} catch (error) {
					this.value('00:00:01');
				}
			}

			if (val == 'text')
			{
				if (this.dialog.bindings)
				{
					if (this.dialog.bindings.data_type != 'text')
						this.value('');
				}
			}
		})
	}

	async save_click ()
	{
		let input = {
			name: this.name(),
			description: this.description(),
			tags: [],
			value: false,
			data_type: this.data_type()
		};

		for (let t of this.selected_tags())
			if (t && t.length)
				input.tags.push(t);

		if (input.data_type == 'bool')
		{
			input.value = this.checked();
		}
		else
			input.value = this.value();

		if (!input.name)
		{
			Grape.alerts.alert({type: 'warning', title:'Warning', message: 'No name provided'});
			return;
		}
		else if (input.data_type != 'bool' && !input.value)
		{
			Grape.alerts.alert({type: 'warning', title:'Warning', message: 'Invalid value provided'});
			return;
		}

		try {
			let response = await fetch(`/api/setting/${input.name}`,
			{
				method: 'PUT',
				body: JSON.stringify(input),
				headers: {'content-type': 'application/json'}
			});
			if (response.status == 200)
				this.dialog.close(true);

		} catch (err) {
			Grape.alerts.alert({type:'error', title: 'eroor', message: `error happened ${err.message}`});
		}
	}

	close_click ()
	{
		this.dialog.close(false);
	}
}

class DialogClass
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'EditSettings';
		this.element = element;

		if (bindings)
		{
			if (bindings.name)
			{
				this.viewModel.name(bindings.name);
				this.viewModel.editing(true);
			}
			if (bindings.description)
				this.viewModel.description(bindings.description);
			if (bindings.tags)
				this.viewModel.selected_tags(bindings.tags || []);
			if (bindings.data_type)
				this.viewModel.data_type(bindings.data_type);
			if (bindings.value)
			{
				this.viewModel.value(bindings.value);
				if (bindings.data_type === 'boolean')
					this.viewModel.checked(bindings.value);
			}
		}
		else
			this.viewModel.editing(false);
	}

	init ()
	{
		document.title = 'Edit Settings';
	}

	format_time(seconds)
	{
		let hours = Math.floor(seconds / 3600);
		let minutes = Math.floor((seconds % 3600) / 60);
		let remaining_seconds = seconds % 60;

		let formatted_hours = String(hours).padStart(2, '0');
		let formatted_minutes = String(minutes).padStart(2, '0');
		let formatted_seconds = String(remaining_seconds).padStart(2, '0');

		return `${formatted_hours}:${formatted_minutes}:${formatted_seconds}`;
	 }
}

export default {
	name: 'EditSetting',
	dialog_class: DialogClass,
	template: template,
	provider: "ps"
}
