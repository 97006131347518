import FieldModel from './FieldModel.js';

const input_defaults = {
	schema: null,
	table: null,
	display_name: null,
	options: {},
	fields: [],
	idx: 0,
};

class FieldsTableModel
{
	constructor(values)
	{
		this.base_object = null;

		this.fields_table_uuid = ko.observable();
		this.schema = ko.observable();
		this.table = ko.observable();
		this.display_name = ko.observable();
		this.idx = ko.observable();
		this.options = ko.observable();
		this.fields = ko.observableArray();

		this.set(values);
	}

	async fetch(table_uuid)
	{
		let requested_id = table_uuid ?? this.fields_table_uuid();
		if (!requested_id || requested_id == '')
			return;

		let res = await Grape.fetches.getJSON('/api/record', {
			schema: 'fields',
			table: 'v_fields_table',
			limit: 10,
			filter: [{
				field: 'fields_table_uuid', op: '=', value: requested_id
			}]
		});

		if (res.status == 'OK')
		{
			if (res.records.length > 0)
				this.set(res.records[0]);
			else
				Grape.alerts.alert({type: 'warning', 'message': 'No table found with specified uuid'});
		}
		else
			Grape.alerts.alert({type: 'error', message: res.message || 'Fail' });
	}

	async save()
	{
		if (!this.schema())
			await Grape.alerts.alert({type: 'warning', message: 'Schema name cannot be empty!'});
		else if (!this.table())
			await Grape.alerts.alert({type: 'warning', message: 'Table name cannot be empty!'});

		else
		{
			let payload = this.serialize();

			if (typeof payload.options === 'string')
			{
				try {
					payload.options = JSON.parse(payload.options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

			return Grape.fetches.postJSON('/api/custom-fields/table/upsert', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/custom-fields/table/delete', {fields_table_uuid: this.fields_table_uuid});
	}

	async move_up() {
		// TODO
	}

	async move_down() {

	}

	async move_field_up() {

	}

	async move_field_down() {

	}

	async set(input)
	{
		this.base_object = input;

		if (!input) return;

		this.fields_table_uuid(input.fields_table_uuid ?? input.uuid);
		this.schema(input.schema ?? input_defaults.schema);
		this.table(input.table ?? input_defaults.table);
		this.display_name(input.table_display_name ?? input_defaults.display_name);
		this.idx(input.table_idx ?? input_defaults.idx);
		this.options(JSON.stringify(input.options) ?? JSON.stringify(input_defaults.options));

		let fields = input.fields ?? input_defaults.fields;
		if (fields.length > 0)
			fields = fields.sort((a, b) => a.idx - b.idx);

		fields = fields.map(f => new FieldModel(f));
		this.fields(fields);
	}


	serialize()
	{
		return {
			fields_table_uuid: this.fields_table_uuid(),
			schema: this.schema(),
			table: this.table(),
			display_name: this.display_name(),
			idx: this.idx(),
			options: this.options()
		};
	}
}

export default FieldsTableModel;
