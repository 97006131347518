
export default {
	name: 'Events.Actions',
	options: {
		refresh: async () => {
			const result = await Grape.fetches.getJSON('/api/record', {
				schema: 'events',
				table: 'v_actions',
				fields: ['namespace', 'name', 'description', 'ui_fields']
			});
			return result.records;
		}
	}
};

