
import template from './AddEditFieldsTable.html';
import FieldsTableModel from './../../lib/models/FieldsTableModel.js'

class AddEditFieldsTableVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;

		let table_data = this.dialog.bindings.fieldsTable || {};
		if (!this.edit)
		{
			let last_table_index = this.dialog.bindings.last_table_idx || 0;
			table_data.table_idx = last_table_index + 1;
		}

		this.fields_table = ko.observable(new FieldsTableModel(table_data));
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let res = await this.fields_table().save();
		if (res)
		{
			if (res?.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({ type:'success', title:'Success', message:'Successfully Saved.' });
			this.dialog.close(res);
		}
	}
}

class AddEditFieldsTableDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditFieldsTableVM(this);
		this.name = 'AddEditFieldsTable';
	}
}

export default {
	name: 'AddEditFieldsTable',
	dialog_class: AddEditFieldsTableDialog,
	provider: 'ps',
	template: template
}
