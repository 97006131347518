import template from './sessions.html'

class ViewSessionsPageViewModel
{
	constructor (page)
	{
		this.page = page;
		this.active_sessions = ko.observableArray([]);
		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.sort_field = ko.observable('valid_from');
		this.sort_order = ko.observable('ASC');

		this.columns = ko.observableArray(['Username', 'Session Started', 'Email', 'IP Address', 'User Agent', 'Session Auth Methods']);
		this.visible_columns = ko.observableArray(['Username', 'Session Started', 'Email', 'IP Address', 'User Agent', 'Session Auth Methods']);

		this.visible_columns.subscribe((newVal) => {
			localStorage.setItem('ps_dashboard.sessions.visible_columns', JSON.stringify(this.visible_columns()));
		});
	}

	back_to_top ()
	{
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	async a_logout_click (row)
	{
		let response = await fetch(`/api/session/logout?session_uuid=${row.session_uuid}`);
		await response.text();

		if (Grape.currentSession.session_uuid == row.session_uuid)
			Grape.navigate('/ui/logout');

		this.page.updateData();
	}

	a_view_click (row)
	{
		Grape.dialog.open('ViewSession', {session: row});
	}

	btn_page_click (page_number)
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click ()
	{
		this.page.updateData();
	}

	sort_column (column)
	{
		if (this.sort_field() === column) {
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		} else {
			this.sort_field(column);
			this.sort_order('ASC');
		}
		this.page.updateData();
	}
}

//Handle interaction with page 
class ViewSessionsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ViewSessionsPageViewModel(this);
		this.name = 'ViewSessionsPage';

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});

		let visible_columns = localStorage.getItem('ps_dashboard.sessions.visible_columns');
		if (visible_columns)
			this.viewModel.visible_columns(JSON.parse(visible_columns));
	}

	async init ()
	{
		document.title = 'Dashboard - Sessions';
		this.updateData();
	}

	async updateData ()
	{
		let result = {
			table: 'v_active_sessions', 
			schema: 'grape',
			offset: 0,
			filter_join: 'AND',
			join: 'OR',
			filter: [],
			sortorder: this.viewModel.sort_order(),
			sortfield: this.viewModel.sort_field()
		}

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '') 
		{
			result.filter.push({ 
				field: 'username', 
				operand: 'ILIKE', 
				value: `%${this.viewModel.search_string()}%` 
			});
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			result.limit = this.viewModel.current_page_size();
			result.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		try
		{
			let sessions = await Grape.fetches.getJSON('/api/record', result);

			if (sessions.status != 'ERROR')
			{
				this.viewModel.active_sessions(sessions.records);
				this.viewModel.page_count(Math.floor(sessions.total/sessions.limit)+1);
			}
			else
				throw new Error(sessions.message || sessions.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error)
		}
	}
}

export default {
	route: '[/]ui/sessions',
	page_class: ViewSessionsPage,
	template: template,
	name: 'sessions',
	title: 'Sessions',
	page_id: 'ps_dashboard.sessions',
	icon: 'fa-regular fa-laptop'
}
