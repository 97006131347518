// DASHBOARD PAGES

import OverviewPage from './overview/overview.js';
import UsersMenuPage from './users_menu/users_menu.js';
//import Backup from './backup/backup.js';
import SettingsPage from './settings/settings.js';
import SubscriptionsPage from './subscriptions/subscriptions.js';
import CustomFields from './custom-fields/custom-fields.js';
import CustomTables from './custom-tables/custom-tables.js';
import JsonMapper from './json_mapper/json_mapper.js';
import Lookups from './lookups/lookups.js';



export default [
	OverviewPage,
	UsersMenuPage,
	//	Backup,
	SettingsPage,
	SubscriptionsPage,
	CustomFields,
	CustomTables,
	JsonMapper,
	Lookups
];
