import template from './settings.html'

class SettingsPageViewModel
{
	constructor (page)
	{
		this.page = page;
		let last_settings_tab = localStorage.getItem('last_settings_tab') || 'system_setup';
		this.selected_tab = ko.observable(last_settings_tab);
		this.setting_categories = ko.observable({});
		this.setting_category_names = ko.observableArray([]);
		this.is_busy = ko.observable(false);
		this.show_hidden = ko.observable(false);

		this.frontpage_settings =
		{
			url: ko.observable(''),
			product_name: ko.observable(''),
			service_name: ko.observable(''),
			site_name: ko.observable('')
		}
	}

	switch_tabs (data, event)
	{
		let tabname = event.currentTarget.getAttribute('data-tabname');
		this.selected_tab(tabname);

		localStorage.setItem('last_settings_tab', tabname);

		document.querySelectorAll('.ps-nav-pill li').forEach(tab => tab.classList.remove('active'));
		event.currentTarget.classList.add('active');
	}

	async btn_edit_setting_click (setting)
	{
		let result = await Grape.dialog.open('EditSetting',
		{
			name: setting.name,
			description: setting.description,
			tags: setting.tags,
			value: setting.original_value(),
			data_type: setting.data_type
		});
		if (result)
			this.page.load_settings();
	}

	async btn_new_setting_click ()
	{
		let result = await Grape.dialog.open('EditSetting', null);
		if (result)
			this.page.load_settings();
	}

	async btn_save_all_click (category_name, e)
	{
		this.is_busy(true);
		let promises = [];
		let saved = [];

		this.setting_categories()[category_name].settings().forEach((setting) => {
			if (setting.value() != setting.original_value())
			{
				promises.push(this.page.save_setting_value({name: setting.name, value: setting.value()}));
				saved.push(setting.value())
			}
		});

		try {
			if (promises.length > 0)
			{
				let results = await Promise.all(promises);

				Grape.alerts.alert({type: 'success', title: 'Saved', message: 'The following setting values have been saved: ' + saved.join(', ')});
				for (let setting_name of results)
				{
					for (let setting of this.setting_categories()[category_name].settings())
						if (setting.name == setting_name)
							setting.original_value(setting.value());

					this.is_busy(false);
				}
				this.is_busy(false);
				this.page.load_settings();
			}
			else
				Grape.alerts.alert({type: 'warning', title: '', message: 'There are no changed values to save.'});
		} catch (err) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: `Error while saving settings ${err.message}`});
		}
	}

	async btn_save_frontpage_click ()
	{
		this.is_busy(true);
		let promises = [];
		let keys = Object.keys(this.frontpage_settings);
		let saved = []
		keys.forEach((n) => {
			promises.push(this.page.save_setting_value({name: 'public.' + n, value: this.frontpage_settings[n]()}));
			saved.push(this.frontpage_settings[n]())
		});
		try {
			await Promise.all(promises);

			Grape.alerts.alert({type: 'success', title: 'Saved', message: 'The following setting values have been saved: ' + saved.join(', ')});
		} catch (err) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: `Error while saving settings: ${err.message}`});
		}
		this.is_busy(false);
	}

	async btn_save_value_click (setting)
	{
		this.is_busy(true);
		try {
			await this.page.save_setting_value({name: setting.name, value: setting.value()});
			setting.original_value(setting.value());
		} catch (err) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: 'Error while saving setting', error: err});
		}
		this.is_busy(false);
	}
}

class SettingsPage
{
	constructor (bindings)
	{
		this.viewModel = new SettingsPageViewModel(this);
		this.bindings = bindings;
	}

	init ()
	{
		document.title = 'Dashboard - Settings';
	}

	updateData ()
	{
		this.load_settings();
	}

	async load_settings ()
	{
		this.viewModel.setting_category_names([]);
		this.viewModel.setting_categories([]);
		this.viewModel.is_busy(true);
		let new_setting_category_names = ['default'];
		let new_setting_categories = {
			'default': {
				name: 'default',
				settings: ko.observableArray([])
			}
		};

		let result = await fetch('/api/setting');
		let data = await result.json();

		for (let setting of data.settings)
		{
			if (setting.data_type == 'boolean')
			{
				if (setting.value == 'false' || !setting.value)
					setting.value = false;
				else
					setting.value = true;
			}

			if (setting.name == 'public.url')
				this.viewModel.frontpage_settings.url(setting.value);
			else if (setting.name == 'public.service_name')
				this.viewModel.frontpage_settings.service_name(setting.value);
			else if (setting.name == 'public.site_name')
				this.viewModel.frontpage_settings.site_name(setting.value);
			else if (setting.name == 'public.product_name')
				this.viewModel.frontpage_settings.product_name(setting.value);
			else
			{
				let ar = setting.name.split('.');
				if (ar.length < 2)
					ar = ['default', setting.name];
				if (new_setting_category_names.indexOf(ar[0]) == -1)
				{
					new_setting_category_names.push(ar[0]);
					new_setting_categories[ar[0]] = {
						name: ar[0],
						settings: ko.observableArray([])
					};
				}
				let v = ko.observable(setting.value);
				new_setting_categories[ar[0]].settings.push({
					name: setting.name,
					original_value: ko.observable(setting.value),
					value: v,
					data_type: setting.data_type,
					tags: setting.tags || [],
					description: setting.description
				});
			}
		}

		this.viewModel.setting_categories(new_setting_categories);
		this.viewModel.setting_category_names(new_setting_category_names);

		this.viewModel.is_busy(false);
	}

	async save_setting_value (setting)
	{
		let response = await fetch(
			`/api/setting/${setting.name}`,
			{
				method: 'PATCH',
				body: JSON.stringify({value: setting.value}),
				headers: {'content-type': 'application/json'}
			});
		let result = await response.json();
	}
}

export default {
	route: '[/]ui/settings',
	page_class: SettingsPage,
	template: template,
	name: 'settings',
	title: 'Settings',
	page_id: 'ps_dashboard.settings',
	icon: 'fa-regular fa-gear',
	idx: 3
}
