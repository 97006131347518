
import template from './AddEditTableField.html';
import FieldModel from '../../lib/models/FieldModel.js'

class AddEditTableFieldVM
{
	constructor(dialog)
	{
		this.dialog = dialog;
		this.edit = this.dialog.bindings.edit;
		this.table_field = ko.observable();
		this.types = ko.observableArray();
		this.fields_tables_map = ko.observable({});
		this.type_config_inputs = ko.observable({});
		this.selected_type = ko.observable(null);

		let field_data = this.dialog.bindings.tableField || {};

		if (!this.edit)
		{
			let last_field_idx = this.dialog.bindings.last_field_idx || 0;
			field_data.idx = last_field_idx + 1;
		}

		this.fields_table = ko.observable(new FieldModel(field_data));

		this.fetch_fields_tables().then(() => {
			this.table_field(this.dialog.bindings.tableField);
		});

		this.selected_type.subscribe((type) => {
			let i = {};
			for (let field of type.data?.ui_fields || [])
			{
				i[field.name] = ko.observable();
			}

			this.type_config_inputs(i);
		});

		this.schema_option = ko.computed(() => {
			return Object.keys(this.fields_tables_map());
		});

		this.table_option = ko.computed(() => {
			if (!this.fields_table())
				return [];

			return this.fields_tables_map()[this.fields_table().schema];
		});

		Grape.cache.get('FieldTypes').then((records) => {
			this.types(records);

			let current_type_name = this.fields_table().options()?.type;
			if (current_type_name)
			{
				let matching_type = records.find(type => type.name === current_type_name);
				if (matching_type)
					this.selected_type(matching_type);
			}
		});
	}

	close()
	{
		this.dialog.close();
	}

	async save()
	{
		let field_type_options = {};
		for (let [k, v] of Object.entries(this.type_config_inputs()))
		{
			field_type_options[k] = v();
		}

		this.fields_table().options(Object.assign({type: this.selected_type().name}, field_type_options));

		let res = await this.fields_table().save();
		if (res)
		{
			if (res.status !== 'OK')
			{
				Grape.alerts.apiError(res);
				return;
			}

			Grape.alerts.alert({type:'success', title:'Success', message:'Successfully Saved.'});
			this.dialog.close(res);
		}
	}

	async fetch_fields_tables()
	{
		let fields_tables = await Grape.tables.select({schema: 'fields', table: 'fields_table'});
		if (!fields_tables?.records)
			return;

		let fields_tables_map = {};
		for (let fields_table of fields_tables.records)
		{
			if (!fields_tables_map[fields_table.schema])
				fields_tables_map[fields_table.schema] = [];

			fields_tables_map[fields_table.schema].push(fields_table.table);
		};
		this.fields_tables_map(fields_tables_map);
	}
}

class AddEditTableFieldDialog
{
	constructor(bindings, element)
	{
		this.bindings = bindings || {};
		this.viewModel = new AddEditTableFieldVM(this); // Name of the ViewModel defined above
		this.name = 'AddEditTableField'; // Unique dialog name
	}
}

export default {
	name: 'AddEditTableField',
	dialog_class: AddEditTableFieldDialog,
	provider: 'ps',
	template: template
}
