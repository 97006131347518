
export default {
	name: 'FieldTypes',
	options: {
		schema: 'grape',
		table: 'v_lookup_value',
		sortfield: 'idx',
		filter: [{ field: 'lookup_name', operand: '=', value: 'FieldTypes' }]
	}
};

