
import template from './api-keys.html';

/**
 * 
 */
class APIKeysVM
{
	constructor(page)
	{
		this.page = page;
		this.element = page.element;

		this.state = ko.observable('view');
		this.status_message = ko.observable('');

		this.records = ko.observableArray();
		this.new_name = ko.observable();
		this.show_edit = ko.observable(false);

		// New/Edit
		this.selectedEditingAPIKey = ko.observable();
		this.allRoleNames = ko.observableArray();
		this.selectedRoleNames = ko.observableArray();
		this.editDescription = ko.observable();

		this.createdResult = ko.observable(null);
	}

	// Load records
	async update()
	{
		this.state('loading');
		this.status_message('Loading keys...');
		try {
			let data = await Grape.fetches.getJSON('/api/record', {schema: 'grape', table: 'v_api_keys'});
			let records = [];
			for (let r of data.records)
				records.push({
					name: r.name,
					roles: r.attributes?.roles || [],
					description: r.attributes.description,
					date_inserted: r.date_inserted,
					last_used: r.last_used,
					uuid: r.api_key_uuid
				});
			this.records(records);
			this.state('view');
		} catch (err) {
			this.state('error');
			this.status_message(err.message);
		}

	}

	async btnSave_click()
	{
		let obj = {
			attributes: {
				description: (this.editDescription() || '').trim(),
				roles: this.selectedRoleNames()
		}};

		if (!obj.attributes.description.length)
		{
			await Grape.alerts.alert({type: 'warning', message: 'Please enter a description for the API key'});
			return;
		}

		if (!obj.attributes.roles.length)
		{
			await Grape.alerts.alert({type: 'warning', message: 'Please select at least one access role to assign to the API key'});
			return;
		}

		let url = `/api/apikey`;
		this.state('loading');
		if (this.selectedEditingAPIKey())
		{
			this.status_message('Updating API key...');
			url = `/api/apikey/${this.selectedEditingAPIKey().uuid}`;
		}
		else
		{
			this.status_message('Creating API key...');
		}
		
		try {
			let response = await fetch(url,
				{
					method: 'POST',
					headers: {'Content-type': 'application/json'},
					body: JSON.stringify(obj)
				}
			);
			let data = await response.json();
			if (!this.selectedEditingAPIKey())
				this.createdResult(data);
			await this.update();
		} catch (err) {
			console.error(err);
			this.state('error');
			this.status_message(err.message);
		}
	}

	async btnDelete_click(apikey)
	{
		if (!(await Grape.alerts.confirm({type: 'warning', title: 'Delete API Key', message: 'Are you sure?'})))
			return;

		console.log('apikey:',apikey);

		try {
			let response = await fetch(`/api/apikey/${apikey.uuid}`, {
				method: 'DELETE'
			});
			if (!response.ok)
				throw new Error(response.statusText);
		} catch (err) {
			this.state('error');
			this.status_message(err.message);
			return;
		}
		Grape.alerts.alert({type: 'success', title: 'success', message: 'API key has been deleted'});
		this.update();
	}


	async btnAdd_click()
	{
		this.state('new');
	}

	async btnUpdate_click(data)
	{
		this.selectedEditingAPIKey(data);
		this.selectedRoleNames(data.roles);
		this.editDescription(data.description);
		this.state('edit');
	}

	copyToClipboard(data)
	{
		navigator.clipboard.writeText(data);
	}
}

/**
 * Banks page class
 */
class APIKeysPage
{
	constructor(bindings, element)
	{
		document.title = 'API Credentials';

		this.bindings = bindings;
		this.element = element;
		this.viewModel = new APIKeysVM(this);
		this.name = 'APIKeysPage';
	}

	async init () {
		const curr_role_names = Grape.currentSession?.roles || [];
		const role_names = curr_role_names.filter((x) => ['all', 'guest', 'any'].indexOf(x) == -1);
		this.viewModel.allRoleNames(role_names);
	}

	async updateData ()
	{
		await this.viewModel.update();
	}

	async teardown ()
	{
	}

}

export default {
	route: '[/]ui/profile/api-keys',
	page_class: APIKeysPage,
	template: template
}

