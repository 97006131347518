

class LiveUpdatesPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
		this.socket;
		window.Grape.LiveUpdates = this;
	}

	on(event, listener){
		return this.socket.on(event, listener);
	}

	off(event, listener){
		return this.socket.off(event, listener);
	}

	once(event, listener){
		return this.socket.once(event, listener);
	}

	chat(to, message){
		this.socket.socket.send(JSON.stringify({to, subject:'chat', data:message}));
	}

	async connect(){
		if (!this.socket)
			this.socket = new Grape.WebSocketModel('/api/live-updates');

		this.socket.on_message = (msg) => {
			if (!msg || !msg.hasOwnProperty('subject') || msg.subject == 'message')
				return;

			this.socket.emit(msg.subject, msg);
		};

		await this.socket.open('json');
	}

	async afterInit()
	{
		if (Grape.currentSession)
			this.connect().then(()=>{console.log('LiveUpdates connected')}).catch((error)=>{console.error(error)});
		else {
			this.socket?.close?.();
		}
	}

	async onSessionChange()
	{
		if (Grape.currentSession)
			this.connect().then(()=>{console.log('LiveUpdates connected')}).catch((error)=>{console.error(error)});
		else {
			this.socket?.close?.();
		}
	}
}

export default LiveUpdatesPlugin;
