

const input_defaults = {
	field_uuid: null,
	name: null,
	display_name: null,
	options: {}
};

class FieldModel 
{
	constructor(params)
	{
		this.base_object = null;

		this.field_uuid = ko_helper.safe_observable();
		this.schema = ko_helper.safe_observable();
		this.table = ko_helper.safe_observable();
		this.name = ko_helper.safe_observable();
		this.display_name = ko_helper.safe_observable();
		this.description = ko_helper.safe_observable();
		this.idx = ko_helper.safe_observable();
		this.options = ko.observable();

		this.set(params);
	}

	async fetch(field_uuid)
	{
		let requested_id = field_uuid ?? this.field_uuid();
		if (!requested_id || requested_id == '')
			return;

		let res = await Grape.fetches.getJSON('/api/record', {
			schema: 'fields',
			table: 'v_field',
			limit: 10,
			filter: [{
				field: 'field_uuid', op: '=', value: requested_id
			}]
		});

		if (res.status == 'OK')
		{
			if (res.records.length > 0)
				this.set(res.records[0]);
			else
				Grape.alerts.alert({type: 'warning', 'message': 'No table found with specified uuid'});
		}
		else
			Grape.alerts.alert({type: 'error', message: res.message || 'Fail' });
	}

	async set(input)
	{
		this.base_object = input;

		if (!input) return;

		this.field_uuid(input.field_uuid ?? input.uuid ?? input_defaults.field_uuid);
		this.name(input.name ?? '');
		this.display_name(input.display_name ?? input_defaults.display_name);
		this.description(input.description ?? '');
		this.options(input.options ?? input_defaults.options);
		this.schema(input.schema ?? '');
		this.table(input.table ?? '');
		this.idx(input.idx);
	}

	async save()
	{
		if (!this.name())
			Grape.alerts.alert({type: 'warning', message: 'Field name cannot be empty!'});
		else
		{
			let payload = this.serialize();

			if (typeof payload.options === 'string')
			{
				try {
					payload.options = JSON.parse(payload.options);
				} catch (err) {
					return {status: 'ERROR', code: 'Invalid Data', message:'Options is not valid JSON'};
				}
			}

			return Grape.fetches.postJSON('/api/custom-fields/field/upsert', payload);
		}
	}

	async delete()
	{
		return Grape.fetches.deleteJSON('/api/custom-fields/field/delete', {field_uuid: this.field_uuid()});
	}

	serialize()
	{
		return {
			field_uuid: this.field_uuid(),
			schema: this.schema(),
			table: this.table(),
			name: this.name(),
			display_name: this.display_name(),
			description: this.description(),
			idx: this.idx(),
			options: this.options(),
		};
	}

}

export default FieldModel;
